
<template>
<div>
  <emulators-banner /> 
  <router-view></router-view>
</div>
</template>

<script>
export default {
  components: {
    "emulators-banner": () => import("@/components/emulatorsBanner.vue"),
  },
}
</script>

<style>
</style>
